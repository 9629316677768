import { parse as parseQs } from 'querystring'
import * as React from 'react'
import {
  calculateDiscount,
  PricingPlan,
} from '../api-client/interface/PricingPlan'
import { Coupon } from '../api-client/interface/RecurlyClient'
import { AddonName, Subscription } from '../api-client/interface/Subscription'
import JustMe from '../assets/img/addons/just-me.svg'
import { PricingPlan as PricingPlanComponent } from '../components/PricingPlan'
import { ADDONS } from '../helpers/image-imports/Addons'
import { CHECKOUT } from '../helpers/image-imports/Checkout'
import { PlanPeriod } from '../pages/BillingPage'

export enum PlanPeriodAbbreviation {
  MONTHLY = 'm',
  YEARLY = 'y',
  BIYEARLY = '2y',
}

export const PLAN_PERIOD_ABBREVIATION: { [x: string]: PlanPeriodAbbreviation } =
  {
    Monthly: PlanPeriodAbbreviation.MONTHLY,
    Yearly: PlanPeriodAbbreviation.YEARLY,
    Biyearly: PlanPeriodAbbreviation.BIYEARLY,
  }

type Props = {
  planPeriod: PlanPeriodAbbreviation
  pricingPlans: PricingPlan[]
  selectedPlan: PricingPlan
  teamAccounts: number
  onPlanPeriodChange: (pp: PlanPeriodAbbreviation) => Promise<void>
  onActivePlanChange: (planCode: string) => void
  onTeamAccountsChange: (teamAccounts: number) => void
  goToAddons: () => void

  coupon: Coupon | null
  couponCode: string
  couponActive: boolean
  couponLoading: boolean
  onCouponCodeChange: (couponCode: string) => void
  onCouponApply: () => void
  onCouponActiveChange: (couponActive: boolean) => void

  subscription: Subscription | null
  onFirstSubscription: boolean

  currentPlanPeriod: PlanPeriod
}

type State = {
  teamAddonVisible: boolean
}

export class CheckoutStep1Container extends React.Component<Props, State> {
  public state: State = {
    teamAddonVisible: false,
  }

  componentDidMount() {
    this.cssFixes()
  }

  render() {
    const displayTrial =
      String(parseQs(window.location.search.slice(1))['click']) === 'trial' &&
      !this.props.subscription

    return (
      <>
        <header className="step-one__header ant-row">
          <div
            className="step-one-header__description ant-col"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <h3>
              Try our new AI powered Cloud IDE, using{' '}
              <span style={{ color: '#7f3f98' }}>Codeanywhere 8!</span>
            </h3>
            <ul>
              <li>
                <img src={CHECKOUT['WarningSign']} alt="" /> New subscriptions
                for Codeanywhere version 6 are no longer available.
              </li>
              <li>
                <img src={CHECKOUT['SuccessInfo']} alt="" /> Don't worry -{' '}
                <strong>
                  &nbsp;your existing subscription will continue to renew &nbsp;
                </strong>{' '}
                as usual, as we have no immediate plans to deprecate these
                subscriptions.
              </li>
              <li>
                <img src={CHECKOUT['Check']} alt="" /> Start with our{' '}
                <strong>&nbsp;free plan&nbsp;</strong> and experience the next
                generation of cloud development. As a valued legacy user, you're
                eligible for <strong>exclusive discounts&nbsp;</strong> on our
                new plans.
              </li>
            </ul>
            <button
              style={{
                margin: '2rem auto',
                paddingLeft: '3rem',
                paddingRight: '3rem',
              }}
              className="checkout-button"
              onClick={() =>
                window.open(
                  'https://billing.app.codeanywhere.com?source=ca6',
                  '_blank'
                )
              }
            >
              Upgrade to Codeanywhere 8
            </button>
          </div>
        </header>
        <div
          className="plan-period__wrapper"
          style={{ marginTop: displayTrial ? '22px' : '44px' }}
        >
          <ul className="plan-period">
            <li
              className="plan-period__item"
              data-active={
                this.props.planPeriod === PlanPeriodAbbreviation.BIYEARLY
              }
            >
              2-year plans
            </li>
            <li
              className="plan-period__item"
              data-active={
                this.props.planPeriod === PlanPeriodAbbreviation.YEARLY
              }
            >
              1-year plans
            </li>
            <li
              className="plan-period__item"
              data-active={
                this.props.planPeriod === PlanPeriodAbbreviation.MONTHLY
              }
            >
              Monthly plans
            </li>
          </ul>
        </div>
        <div className="section payment-plans-wrapper">
          <div className="payment-plans">
            {this.props.pricingPlans.map(plan => {
              return (
                <PricingPlanComponent
                  active={this.props.selectedPlan.code === plan.code}
                  planName={plan.name}
                  planCode={plan.code}
                  key={plan.code}
                  planMonthlyPrice={
                    this.props.planPeriod === PlanPeriodAbbreviation.MONTHLY
                      ? plan.price.month
                      : this.props.planPeriod === PlanPeriodAbbreviation.YEARLY
                      ? plan.price.year
                      : plan.price.biyear
                  }
                  discountPercent={parseFloat(
                    calculateDiscount(plan, this.props.planPeriod)
                  )}
                  biYearOriginalPrice={plan.price.month * 24}
                  isBestDeal={
                    plan.code.includes('standard') &&
                    this.props.planPeriod === PlanPeriodAbbreviation.BIYEARLY
                  }
                  planPeriod={this.props.planPeriod}
                  onClick={string => {
                    return
                  }}
                />
              )
            })}
          </div>
          <div className="payment-plans-info">
            <div
              style={{
                display: this.props.subscription ? 'block' : 'none',
              }}
            >
              <a href="/cancel-membership" rel="noopener noreferrer">
                Cancel Membership
              </a>
            </div>
          </div>
        </div>
        <section className="step-one__team">
          <h3 className="step-one-team__title">
            Will you be working in the team?
          </h3>
          <h4
            style={{
              display: this.props.selectedPlan.code.includes('basic')
                ? 'inline'
                : 'none',
            }}
          >
            Team accounts are not available in the Basic plan.{' '}
            <u
              style={{
                cursor: 'pointer',
                color: 'rgba(127, 63, 152, 1)',
              }}
            >
              Upgrade to Standard
            </u>{' '}
            if you want to invite your students and colleagues, share custom
            stacks and more.
          </h4>
          <div className="step-one-team__options">
            <div
              className={`step-one-team__option`}
              data-active={!this.props.teamAccounts}
            >
              <div className="payment-method-card__radio" />
              <div className="step-one-team__name">Just me</div>
              <div className="step-one-team__icon">
                <img src={JustMe} alt="Team icon" />
              </div>
            </div>

            <div
              className={`step-one-team__option${
                this.props.selectedPlan.code.includes('basic')
                  ? ' disabled'
                  : ''
              }`}
              {...(this.props.selectedPlan.code.includes('basic')
                ? {
                    'data-balloon':
                      'Team accounts are not available in the Basic plan.',
                    'data-balloon-pos': 'up',
                  }
                : {})}
              data-active={!!(this.props.teamAccounts > 0)}
            >
              <div className="payment-method-card__radio" />
              <div className="step-one-team__name">Team</div>
              <div className="step-one-team__icon">
                <img src={ADDONS[AddonName.MultipleAccounts]} alt="Team icon" />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

  private cssFixes() {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        entries => {
          // no intersection with screen
          if (entries[0].intersectionRatio === 0) {
            const container = document.querySelectorAll('.addon-picker-footer')
            container.forEach(c => c.classList.add('is-sticky'))
            this.setState({
              teamAddonVisible: false,
            })
          }

          // fully intersects with screen
          else if (entries[0].intersectionRatio === 1) {
            const container = document.querySelectorAll('.addon-picker-footer')
            container.forEach(c => c.classList.remove('is-sticky'))
            this.setState({
              teamAddonVisible: true,
            })
          }
        },
        { threshold: [0, 1] }
      )

      const body = document.querySelector('body')
      if (body && !body.classList.contains('body--type-checkout')) {
        body.classList.add('body--type-checkout')
      }

      const stickyBarriers = document.querySelectorAll('.sticky-barrier')
      Array.prototype.forEach.call(stickyBarriers, function (sb) {
        observer.observe(sb)
      })
    }
  }

  private handleContinueToAddons = () => {
    if (!this.state.teamAddonVisible) {
      document.querySelector('.step-one__team')!.scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      this.props.goToAddons()
    }
  }
}
